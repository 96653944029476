import { Device as TwilioDevice } from '@twilio/voice-sdk';
import { v4 as uuidv4 } from 'uuid';
import TwilioVoiceService from './TwilioVoiceService'; // Import necessary dependencies and modules.
import debounce from 'debounce';
import SaleforceService from './SaleforceService';
import { store } from '../app/store';
import { CallActivityDirectionEnum } from '../Enums/CallActivityActionType';
import { addCallTranscript, setInterimTranscription, addBookmark } from '../slices/callTranscript';
import RealTimeClient from './RealTimeClient';
import logger from '../Util/Logger/logger';

// Define types for your custom enums and other types used in the class.
enum RttCommandsEnum {
    // Define your enum values here.
    DISCONNECT = "normal_disconnect",
    STREAM_DATA_MIC = "stream_mic",
    STREAM_DATA_PHONE = "stream_phone",
    METADATA = "metadata",
    START_PROCESSING = "start_processing",
    MANUAL_BOOKMARK = "manual_bookmark"
}

enum RttResponseEnum {
    OK = "ok",
    READY = "ready",
    CONTINUE = "continue",
    ERROR = "error",
    TRANSCRIPT = "transcript",
    BOOKMARK = "bookmark",
    MANUAL_BOOKMARK = "manual_bookmark",
    UNKNOWN = "unknown",
}

// enum RttManualBookmarkEventType {
//     Commit = 'Commit',
//     CommitSuccess = 'CommitSuccess',
//     CommitFailure = 'CommitFailure',
// }

const LogTitle = 'StreamProcessService';

class StreamProcessService {
    private _callRecordId: string;
    private _streamConnection: any;
    private _audioContext: AudioContext | null | undefined;
    private _micStreamSource = null;
    private _encoderOptions = {};
    private _phoneStreamSource = null;
    private _micMediaRecorder = null;
    private _phoneMediaRecorder = null;
    private _websocket: WebSocket | null = null;
    private _buffer: any[] = []; // Define a more specific type for buffer if possible.
    private _camturedBookmark: any[] = [];

    private _connected: boolean = false;
    private _metadataSent: boolean = false;
    private _processing: boolean = false;
    private _stopRequested: boolean = false;

    constructor(callRecordId: string) {
        this._callRecordId = callRecordId;
        this._streamConnection = TwilioVoiceService.callConnection;
        this._audioContext = TwilioDevice.audioContext;
        this._encoderOptions = {
            numberOfChannels: 1,
            encoderPath: '/assets/scripts/encoderWorker.min.js',
            streamPages: true,
            encoderBitRate: 128000,
            originalSampleRateOverride: 48000,
            encoderSampleRate: 48000,
            recordingGain: 1,
        };
        this._camturedBookmark = [];
    }

    start = () => {
        this._startMicrophoneStream();
        this._processPhoneStream();

        this._websocket = new WebSocket(store.getState().auth?.authUser?.rtaspUrl);
        this._websocket.binaryType = 'blob';

        this._websocket.onmessage = this._websocketCallback;

        this._websocket.onopen = () => {
            this._connected = true;
            this._sendMetadata();
        };

        this._websocket.onerror = (error) => {
            logger.info(LogTitle, 'web socket error callID', { error, callID: this._callRecordId });
        };

        this._websocket.onclose = (event) => {
            logger.info(LogTitle, 'web socket closed callID', { event, callID: this._callRecordId });
        };
    };

    muteStreams = () => {
        if (this._micMediaRecorder !== null) {
            //@ts-ignore
            this._micMediaRecorder.setRecordingGain(0);
        }

        if (this._phoneMediaRecorder !== null) {
            //@ts-ignore
            this._phoneMediaRecorder.setRecordingGain(0);
        }
    };

    unmuteStreams = () => {
        if (this._micMediaRecorder !== null) {
            //@ts-ignore
            this._micMediaRecorder.setRecordingGain(1);
        }

        if (this._phoneMediaRecorder !== null) {
            //@ts-ignore
            this._phoneMediaRecorder.setRecordingGain(1);
        }
    };

    didReceiveCallHoldUpdate = (callRecordId: string, isCallHold: boolean) => {
        logger.info(LogTitle, 'didReceiveCallHoldUpdate', { callRecordId, isCallHold });

        if (isCallHold) {
            this.muteStreams();
        } else {
            //@ts-ignore
            this._micMediaRecorder?.stop();
            //@ts-ignore
            this._phoneMediaRecorder?.stop();
            this._streamConnection = TwilioVoiceService.callConnection;
            this._audioContext = TwilioDevice.audioContext;

            this._startMicrophoneStream();
            this._processPhoneStream();
        }
    };

    stop = () => {
        this._stopRequested = true;

        //@ts-ignore
        this._phoneStreamSource?.disconnect();
        //@ts-ignore
        this._micStreamSource?.disconnect();

        this._phoneStreamSource = null;
        this._micStreamSource = null;
        //@ts-ignore
        this._micMediaRecorder?.stop();
        //@ts-ignore
        this._phoneMediaRecorder?.stop();

        this._micMediaRecorder = null;
        this._phoneMediaRecorder = null;
    };

    createManualBookmark = (startTime: number, endTime: number) => {
        const bookmarkMarker = { id: uuidv4(), startTime, endTime };
        logger.info(LogTitle, `${this._callRecordId} Create Manual Bookmark`, bookmarkMarker);

        this._buffer.push({
            type: RttCommandsEnum.MANUAL_BOOKMARK,
            buffer: bookmarkMarker,
        });

        // Define types for AddBookmarkFeature and store.dispatch if not already done.
        // store.dispatch(AddBookmarkFeature.Actions.trackManualBookmark(this._callRecordId, bookmarkMarker));
    };

    _startMicrophoneStream = () => {
        if (!this._streamConnection) {
            return;
        }
        let mediaStream = this._streamConnection?._mediaHandler?.stream;
        if (mediaStream instanceof MediaStream) {
            const buffer = this._buffer;
            //@ts-ignore
            this._micStreamSource = this._audioContext?.createMediaStreamSource(mediaStream);
            // @ts-ignore
            this._micMediaRecorder = new Recorder(this._encoderOptions);
            // @ts-ignore
            this._micMediaRecorder.ondataavailable = (msg: any) => {
                if (msg) {
                    buffer.push({
                        type: RttCommandsEnum.STREAM_DATA_MIC,
                        buffer: msg.buffer,
                    });
                }
            };

            // @ts-ignore
            this._micMediaRecorder.start(this._micStreamSource);
            logger.info(`${LogTitle} Microphone Stream started For CallId ${this._callRecordId}`);
        } else {
            logger.error(`${LogTitle} Microphone Invalid media stream: ${mediaStream}`);
        }
    }

    _processPhoneStream = () => {
        if (!this._streamConnection) {
            logger.error(`${LogTitle} _processPhoneStream For ${this._callRecordId} No stream connection available`);
            return;
        }

        const buffer = this._buffer;

        const setupPhoneStream = () => {
            let mediaStream = this._streamConnection?._mediaHandler?.pcStream;
            if (mediaStream instanceof MediaStream) {
                //@ts-ignore
                this._phoneStreamSource = this._audioContext?.createMediaStreamSource(mediaStream);
                //@ts-ignore
                this._phoneMediaRecorder = new Recorder(this._encoderOptions);
                //@ts-ignore
                this._phoneMediaRecorder.ondataavailable = (msg: any) => {
                    if (msg) {
                        buffer.push({
                            type: RttCommandsEnum.STREAM_DATA_PHONE,
                            buffer: msg.buffer,
                        });
                    }
                };

                //@ts-ignore
                this._phoneMediaRecorder?.start(this._phoneStreamSource);
                logger.info(`${LogTitle} PhoneStream started For CallId ${this._callRecordId}`);
            } else {
                logger.error(`${LogTitle} PhoneStream Invalid media stream: ${mediaStream}`);
            }
        };

        this._streamConnection._mediaHandler.oniceconnectionstatechange = (state: string) => {
            if (state === 'connected') {
                setupPhoneStream();
            } else {
                logger.error(`${LogTitle} ICE connection is ${state} not connected For CallId ${this._callRecordId}`);
            }
        };

        if (this._streamConnection._mediaHandler._iceState === 'connected') {
            setupPhoneStream();
        } else {
            logger.error(`${LogTitle} ICE state is ${this._streamConnection._mediaHandler._iceState}, waiting for connected state for ${this._callRecordId}`);
        }
    }

    private _sendBuffer = () => {
        if (this._stopRequested && this._buffer.length === 0) {
            this._websocket?.close(1000, RttCommandsEnum.DISCONNECT);
            return;
        }

        try {
            const data = this._buffer.shift();

            if (data) {
                switch (data.type) {
                    case RttCommandsEnum.MANUAL_BOOKMARK:
                        this._websocket?.send(`${RttCommandsEnum.MANUAL_BOOKMARK} ${JSON.stringify(data.buffer)}`);
                        break;
                    default:
                        this._websocket?.send(data.type);
                        this._websocket?.send(data.buffer);
                }
            } else {
                this._waitAndRetry();
            }
        } catch (error) {
            logger.log(LogTitle, 'Failed to send buffer callId', { error, callID: this._callRecordId });
            this._waitAndRetry();
        }
    };

    private _sendMetadata = () => {
        const connection = TwilioVoiceService.callConnection;

        if (!connection) {
            logger.info(LogTitle, 'No connection found for call record ID', { callRecordId: this._callRecordId });
            return;
        }

        const user = store.getState()?.auth?.user;
        const tenantCode = store.getState()?.auth?.authUser?.tenantCode;
        const callingContact = store.getState()?.callingContact.data;

        const callDetails = TwilioVoiceService.getCallDetailsFromConnection(connection);
        const isOutgoing = callDetails?.callDirection === CallActivityDirectionEnum.outgoing;
        const correlationToken = isOutgoing ? TwilioVoiceService.callConnection.CorrelationToken : uuidv4();
        let contactName= callDetails?.contactNumber;
    
        if (callingContact?.remoteId) {
           contactName = `${callingContact?.firstName ?? ''} ${callingContact?.lastName ?? ''}`
         } else {
           contactName = 'Unknown';
         }

        const metadata = {
            tenantCode,
            correlationToken,
            callRecordId: this._callRecordId,
            userId: user.id,
            userDisplayName: user?.displayName || `${user.firstName} ${user.lastName}`,
            contactId: callDetails?.contactId,
            contactName: contactName,
            contactNumber: callDetails?.contactNumber,
        };
        
        const metaDataString = JSON.stringify(metadata);
        logger.info(LogTitle, '_sendMetadata: ', metaDataString);
        this._websocket?.send(`${RttCommandsEnum.METADATA} ${metaDataString}`);
        this._metadataSent = true;
    };

    private _websocketCallback = (message: { data: string }) => {
        logger.info(LogTitle, '_websocketCallback: ', message.data);

        if (typeof message.data === 'string') {
            const type = this._resolveResponseEnum(message.data);
            switch (type) {
                case RttResponseEnum.OK:
                    this._handleOkResponse();
                    break;
                case RttResponseEnum.READY:
                    this._handleReadyResponse();
                    break;
                case RttResponseEnum.CONTINUE:
                    this._handleContinueResponse();
                    break;
                case RttResponseEnum.TRANSCRIPT:
                    this._handleTranscriptResponse(message.data);
                    break;
                case RttResponseEnum.BOOKMARK:
                    this._handleBookmarkResponse(message.data);
                    break;
                case RttResponseEnum.MANUAL_BOOKMARK:
                    this._handleManualBookmarkResponse(message.data);
                    break;
                case RttResponseEnum.ERROR:
                    this._handleErrorResponse(message.data);
                    break;
                default:
                    logger.info(LogTitle, 'Unknown message with callid', { message: message.data, callid: this._callRecordId });
            }
        }
    };

    private _handleOkResponse = () => {
        if (this._connected && this._metadataSent && !this._processing) {
            this._websocket?.send(RttCommandsEnum.START_PROCESSING);
        }
    };

    private _handleReadyResponse = () => {
        this._processing = true;
        this._sendBuffer();
    };

    private _handleContinueResponse = () => {
        this._sendBuffer();
    };

    private _handleTranscriptResponse = (data: string) => {
        const transcriptJson = data.substring(RttResponseEnum.TRANSCRIPT.length).trim();
        const transcriptionSegment = JSON.parse(transcriptJson);

        if (!transcriptionSegment.transcript) { return; }

        var TRANSCRIPTMC = "rocketphone__TRANSCRIPTMC__c";

        if (transcriptionSegment.isFinal) {
            SaleforceService.publishObject({ callRecordId: this._callRecordId, segment: transcriptionSegment }, TRANSCRIPTMC);
            store.dispatch(addCallTranscript({ callRecordId: this._callRecordId, transcript: transcriptionSegment }));
            store.dispatch(setInterimTranscription({ callRecordId: this._callRecordId, transcript: null, source: transcriptionSegment.source }));
        } else {
            store.dispatch(setInterimTranscription({ callRecordId: this._callRecordId, transcript: transcriptionSegment, source: transcriptionSegment.source }));
        }
    };

    renderBookmarkType = (bookmarkType: string) => {
        let result = '';

        switch (bookmarkType) {
            case 'Case':
                result = 'Case';
                break;
            case 'Opportunity':
                result = 'Opportunity';
                break;
            case 'ScheduleCallback':
                result = 'ScheduleCallback';
                break;
            default:
                result = '';
        }

        return result;
    };

    private _handleBookmarkResponse = (data: string) => {
        const bookmarkJson = data.substring(RttResponseEnum.BOOKMARK.length).trim();
        const bookmarkCaptureMessage = JSON.parse(bookmarkJson);
        logger.info(LogTitle, `bookmarkCaptureMessage for callID: ${this._callRecordId}`, bookmarkCaptureMessage);
        if (bookmarkCaptureMessage) {
            RealTimeClient.handleBookmarkResponse(bookmarkCaptureMessage);
            // store.dispatch(addBookmark(bookmarkCaptureMessage));
            // // screen Pop to specifid action with bookmark id as ref
            // const { callData: { id: callRecordId, actions: rawActions, bookmarks } } = bookmarkCaptureMessage;
            // console.log(LogTitle, "bookmarkCaptureMessage actions", rawActions);

            // if(bookmarks?.length > 0){
            //     const lastBookmark = bookmarks[bookmarks.length - 1];
            //     let bookmarkObject = {bookmarkType: 'All', bookmarkData: lastBookmark};
            //     var BOOKMARKMC = "BOOKMARKMC__c";
            //     SaleforceService.publishObject(bookmarkObject, BOOKMARKMC);
            //     console.log(LogTitle, "bookmarkObject streamProcess", bookmarkObject);
            // }

            // if (rawActions?.length > 0) {
            //     const lastElement = rawActions[rawActions.length - 1];
            //     let isExist = this._camturedBookmark.find(b => b?.id === lastElement.id);
            //     if (isExist) {
            //         return;
            //     }

            //     if ((lastElement?.actionType === 'Case' || lastElement?.actionType === 'Opportunity' || lastElement?.actionType === 'ScheduleCallback') && !lastElement?.remoteId) {
            //         const referenceId = `${lastElement.id}|${callRecordId}`;
            //         const callingContact = store.getState()?.callingContact?.data;
            //         let contactType = callingContact?.contactType;
            //         let contactRemoteId = callingContact?.remoteId;
                    
            //         let defaultValue = {}
            //         let objectName = null;

            //         if (lastElement?.actionType === 'ScheduleCallback') {
            //             if (contactType === 'Contact' || contactType === 'Lead') {
            //                 objectName = 'Task';
            //                 defaultValue = { ReferenceId__c: referenceId, WhoId: contactRemoteId }
            //             } else if (contactType === 'Account') {
            //                 objectName = 'Task';
            //                 defaultValue = { ReferenceId__c: referenceId, WhatId: contactRemoteId }
            //             } 
            //         } else {
            //             if (contactType === 'Contact' || contactType === 'Account') {
            //                 objectName = lastElement?.actionType === 'Case' ? 'Case': 'Opportunity';
            //                 if (contactType === 'Contact') {
            //                     defaultValue = { ReferenceId__c: referenceId, ContactId: contactRemoteId }
            //                 } else {
            //                     defaultValue = { ReferenceId__c: referenceId, AccountId: contactRemoteId }
            //                 }
            //             }
            //         }

            //         var SAMPLEMC = "SAMPLEMC__c";

            //         if (objectName && contactRemoteId) {
            //           this._camturedBookmark.push(lastElement);
            //           const bookmark = bookmarks.find((bm: any) => bm.id === lastElement?.actionTriggerId);
            //           console.log('bookmark for publishing:', bookmark);
            //           const snackbarData = { bookmarkType: this.renderBookmarkType(lastElement?.actionType) };
            //           store.dispatch(showSnackbar({ snackbarType: SnackbarType.Bookmark, snackbarData }));

            //           SaleforceService.openObjectCreationModal(objectName, defaultValue);
            //           SaleforceService.publishObject(bookmark, SAMPLEMC);
            //         }
            //     }
            // }
        }
    };

    private _handleManualBookmarkResponse = (data: string) => {
        // const manualbookmarkJson = data.substring(RttResponseEnum.MANUAL_BOOKMARK.length).trim();
        // const { type, bookmarkMarker, bookmarkRequest, callRecordId } = JSON.parse(manualbookmarkJson);
        // switch (type) {
        //     case RttManualBookmarkEventType.Commit:
        //         // store.dispatch(AddBookmarkFeature.Actions.trackManualBookmarkCommit(callRecordId, bookmarkMarker, bookmarkRequest));
        //         break;
        //     case RttManualBookmarkEventType.CommitSuccess:
        //         // store.dispatch(AddBookmarkFeature.Actions.trackManualBookmarkCommitSuccess(callRecordId, bookmarkMarker, bookmarkRequest));
        //         break;
        //     case RttManualBookmarkEventType.CommitFailure:
        //         // store.dispatch(AddBookmarkFeature.Actions.trackManualBookmarkCommitFailure(callRecordId, bookmarkMarker, bookmarkRequest));
        //         break;
        // }
    };

    private _handleErrorResponse = (data: string) => {
        logger.info(LogTitle, 'RTAP error', { data });
    };

    private _waitAndRetry = debounce(() => {
        this._sendBuffer();
    }, 1000);

    private _resolveResponseEnum = (data: string): RttResponseEnum => {
        logger.info(LogTitle, '_resolveResponseEnum: ', data);
        if (data.startsWith(RttResponseEnum.OK)) {
            return RttResponseEnum.OK;
        }

        if (data.startsWith(RttResponseEnum.READY)) {
            return RttResponseEnum.READY;
        }

        if (data.startsWith(RttResponseEnum.CONTINUE)) {
            return RttResponseEnum.CONTINUE;
        }

        if (data.startsWith(RttResponseEnum.ERROR)) {
            return RttResponseEnum.ERROR;
        }

        if (data.startsWith(RttResponseEnum.TRANSCRIPT)) {
            return RttResponseEnum.TRANSCRIPT;
        }

        if (data.startsWith(RttResponseEnum.BOOKMARK)) {
            return RttResponseEnum.BOOKMARK;
        }

        if (data.startsWith(RttResponseEnum.MANUAL_BOOKMARK)) {
            return RttResponseEnum.MANUAL_BOOKMARK;
        }

        return RttResponseEnum.UNKNOWN;
    }
}

export default StreamProcessService;