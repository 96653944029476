import { store } from '../../app/store';
import PhoneNumberService from '../PhoneNumberService';
import CallCentreService from '../CallCentre/CallCentre';
import CallConstants from '../../constants/Call';
import { ModalType } from '../../constants/ModalType';
import { SnackbarType } from '../../constants/SnackbarType';
import { CallBarState } from '../../constants/CallBarState';
import { CallRecordWrapUpStatusEnum } from '../../Enums/CallActivityActionType';
import { showSnackbar } from '../../slices/snackbar';
import { showGdprModal } from '../../slices/gdprModal';
import { showAlertModal } from '../../slices/alertModal';
import { updateCallBarState } from '../../slices/callCentre';
import { addCallingContact } from '../../slices/callingContact';
import TwilioVoiceService from '../../Services/TwilioVoiceService';
import CallActionsService from '../../Services/DialPad/CallActions';
import logger from '../../Util/Logger/logger';

class OutboundCallService {
  static createInitiateCallRequestParams = (
      user: any, 
      contact: any, 
      contactNumber: any, 
      dialFromNumber: any, 
      assignedTeamId = null, 
      callEventRemoteId = null, 
      callListId = null) => {
      
      let contactDetails = contact;
      if (!contactDetails || (typeof contactDetails === 'object' && Object.keys(contactDetails).length === 0)) {
        contactDetails = {contactType: 'Unknown', phone: contactNumber};
      }

    return {
      from: dialFromNumber,
      fromId: user?.id,
      to: contactNumber,
      assignedTeamId,
      callEventRemoteId,
      callListId,
      contactDetails,
    };
  }

  static handleWrapUpCall = (userWrapUpStatus: any, history: any) => {
    history.push(`/call-centre/history/${userWrapUpStatus?.callRecordID}`);
  }

  // This method is used to get the Call Record form the backend before we make a connection to Twilio.
  // This way we can pass in the call record id and call forward chain reference into Twilio.
  static initiateCallRequest = async (
    authData: any, 
    tenantSettings: any, 
    contact: any, 
    contactNumber: any, 
    dialFromNumber: any, 
    callEventId: any) => {

    const tenantCode = authData.authUser.tenantCode;
    const accessToken = authData.authUser.AccessToken;
    const user = authData.user;
    const assignedTeam = authData.userTeam;

    //Check for if 'Local Presence' is selected [Hyper Local Feature]
    // If the selected User has selected Local Presence, we need to get the Local Number
    // If we cannot get the Local Number we default into the Team Number or the Organization Number
    if(dialFromNumber === CallConstants.OutgoingCallIdEnums.LocalPresence) {
      try {
        const response = await CallActionsService.getLocalNumber(tenantCode, accessToken, contactNumber);
        if (response?.data && response?.data !== null) {
          dialFromNumber = response?.data?.number;
        } 
        else if (response?.data === null && assignedTeam) {
          dialFromNumber = assignedTeam?.number?.number;
        } else {
          dialFromNumber = tenantSettings?.phoneNumber?.number;
        }
      } catch (error) {
        dialFromNumber = tenantSettings?.phoneNumber?.number; // setting org number as fallback
      }
    }

    if (dialFromNumber) {
      let assignedTeamId = null;
      if (dialFromNumber === assignedTeam?.number?.number) {
        assignedTeamId = assignedTeam ? assignedTeam?.id : null;
      }
  
       const isOnline = store.getState()?.networkConnection?.connection;

       if (!isOnline) {
         const msg =
           "Unable to place the call. Please check your internet connection and try again.";
         logger.error(`initiateCallRequest UserId: ${user?.id} ${msg}`);
         store.dispatch(
           showSnackbar({
             snackbarType: SnackbarType.Error,
             snackbarData: { message: msg },
           })
         );
         return; // Abort the call
       }

       // Check audio device permissions before proceeding
       const hasAudioPermission = await TwilioVoiceService.checkAudioPermissions();
       if (!hasAudioPermission) {
         const msg = "Microphone access is required to make a call.";
         logger.error(`initiateCallRequest UserId: ${user?.id} ${msg}`);
         store.dispatch(
           showSnackbar({
             snackbarType: SnackbarType.Error,
             snackbarData: { message: msg },
           })
         );
         return; // Abort the call
       }

      const callInitiateRequest =
        this.createInitiateCallRequestParams(user, contact, contactNumber, dialFromNumber, assignedTeamId, callEventId, null);
  
      try {
  
        const response = await CallCentreService.initiateCall(tenantCode, accessToken, callInitiateRequest);
        const callRecord = response.data?.data;
        contact = contact?.remoteId ? {...contact, contactId: callRecord?.contactDetails?.id} : callRecord?.contactDetails;
        store.dispatch(addCallingContact(contact));
        store.dispatch(updateCallBarState(CallBarState.CALLING));
  
        this.initiateOutboundCall(user, tenantSettings, contact, contactNumber, dialFromNumber, callRecord, callEventId, assignedTeamId);
      } catch (error) {
        throw error;
      }

    } else {
      const snackbarData = { message: 'Dial out number hasn\'t been assigned.'};
      store.dispatch(showSnackbar({ snackbarType: SnackbarType.Error, snackbarData }));
    }
    
  };

  // This method is used to initiate the calling sequence when a Contact is selected.
  // Since we have the Contact Details, we check for GDPR consent of that contact.
  static initiateOutboundCallWithGdpr = ( 
    authData: any, 
    settingsData: any,
    contact: any, 
    contactNumber: any, 
    dialFromNumber: any, 
    callEventId: any = null) => 
    {
    const tenantSettings = settingsData.tenantSettings;
    let countryCodes = tenantSettings.outboundCountryCode ??  ['44'];
    const userWrapUpStatus = authData.userWrapUpStatus;
    const unWrappedCallAvailable = userWrapUpStatus?.status === CallRecordWrapUpStatusEnum.Pending;
    const isCallDispositionNotMandatory = tenantSettings?.tenantCallSettings?.isCallDispositionNotMandatory;
    const isGdprDisabled = tenantSettings?.tenantCallSettings?.isGdprDisabled;

    if (unWrappedCallAvailable && !isCallDispositionNotMandatory) {
      store.dispatch(showAlertModal({ contact, contactNumber, dialFromNumber, callEventId }));
    } else {
      const numberToDial = PhoneNumberService.getNumber(contactNumber);

      if (countryCodes?.includes(numberToDial?.countryCallingCode)) {
        const gdprConsentNotGiven = contact?.gdprSettings?.callConsent;

        if (gdprConsentNotGiven && !isGdprDisabled) {
          store.dispatch(showGdprModal({ contact, contactNumber, dialFromNumber, callEventId, modalType: ModalType.Gdpr }));
        } else {
          this.initiateCallRequest(authData, tenantSettings, contact, contactNumber, dialFromNumber, callEventId);
        }
      } else {
        store.dispatch(showGdprModal({ modalType: ModalType.Alert }));
        // TODO: add a modal
        // AlertDialogService.openAlertDialog('Unable to Dial Number', 'Your license does not include international numbers.', 'Close Window');
      }
    }
  }

  // This method is primarily used when a contact is not selected
  // Usually called from the Navigatioon Dialler when an new number not associated with any contact is called.
  // This is put is a seperate method to account for any change in calling functionality for this specefic scenario
  static initiateUnknownContactOutboundCall = (authData: any, settingsData: any, dialToNumber: any, dialFromNumber: any) => {
    const tenantSettings = settingsData.tenantSettings;
    let countryCodes = tenantSettings.outboundCountryCode ?? ['44'];
    const userWrapUpStatus = authData.userWrapUpStatus;
    const unWrappedCallAvailable = userWrapUpStatus?.status === CallRecordWrapUpStatusEnum.Pending;

    if (unWrappedCallAvailable) {
      logger.warn('It looks like your last call did not close properly');
      // TODO: add a modal
      // AlertDialogService.openAlertDialog(
      //   'Wrap up call to continue',
      //   'It looks like your last call did not close properly. You must wrap the call up before you can dial out again',
      //   'Go back',
      //   undefined,
      //   'Go to Call wrap up',
      //   () => this.handleWrapUpCall(userWrapUpStatus, history),
      // );
    } else {
      const numberToDial = PhoneNumberService.getNumber(dialToNumber);

      if (countryCodes?.includes(numberToDial?.countryCallingCode)) {
        this.initiateCallRequest(authData, tenantSettings, null, dialToNumber, dialFromNumber, null);
      } else {
        store.dispatch(showGdprModal({ modalType: ModalType.Alert }));
      }
    }
  }

  static initiateOutboundCall = (
    user: any, 
    tenantSettings: any, 
    contact: any, 
    contactNumber: any, 
    dialFromNumber: any, 
    callRecord: any, 
    callEventId: any, 
    assignedTeamId = null) => {
    //const userTeams = authState.userTeams(store.getState());
    const userContactNumber = dialFromNumber || tenantSettings?.phoneNumber?.number;
    //const assignedTeam = userTeams?.length > 0 ? userTeams?.[0] : null;

    const callRequest = TwilioVoiceService.createOutboundCallConnectParams(
          user, 
          userContactNumber,
          contact, 
          contactNumber,
          assignedTeamId, 
          callEventId, 
          null, 
          callRecord?.id, 
          callRecord?.callForwardChainReference, 
          callRecord?.note?.plainText);
          
      TwilioVoiceService.connect(user, callRequest);
  }
}

export default OutboundCallService;
