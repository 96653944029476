import React, { forwardRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '../Avatar';
import Button, { ButtonVariant } from '../Button';
import Typography, { TypographyVariant } from '../Typography';
import IconButton, { IconButtonSize, IconButtonTheme } from '../IconButton';
import CallConstants from '../../constants/Call';
import { addCallingContact, setMissedCallCallee } from '../../slices/callingContact';
import SaleforceService from '../../Services/SaleforceService';
import TwilioVoiceService from '../../Services/TwilioVoiceService';
import CallCentreService from '../../Services/CallCentre/CallCentre';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { IncomingCallPanelProps } from './IncomingCallPanel.types';
import IncomingCallPanelThemer from './IncomingCallPanel.theme';
import { faBuilding, faPhone, faPhoneHangup } from '@fortawesome/pro-solid-svg-icons';

const IncomingCallPanelComponent = forwardRef<HTMLDivElement, IncomingCallPanelProps>((props, ref) => {
    const { id, onCallAnswerPress, onCallHangupPress } = props;

  const dispatch = useAppDispatch();
  const authData = useAppSelector((state: any) => state.auth);
  const incomingCallData = useAppSelector((state: any) => state.callCentre.incomingCallInfo);
  const contactSearchByPhoneDataCount = incomingCallData?.contacts?.length || 0;
  const callDetails = incomingCallData?.callDetails;
  const incomingCallType = incomingCallData?.callType;

  const locationNode = !(callDetails?.traversedRoutes) ? null : (
    <Typography.H4 variant={TypographyVariant.light}
      style={{fontWeight: 600,
        fontSize: 14,
        lineHeight: '21px',}}
    >
      {callDetails?.traversedRoutes?.map((route: any) => `${route.RouteCode} (${route.RouteName})`).join(' > ')}
    </Typography.H4>
  );

  const getProtentialContact = () => {
    let potentialContact;
    const contactRemoteId = callDetails?.contactRemoteId;

    if (incomingCallData?.contacts?.length > 0) {
      potentialContact = incomingCallData?.contacts?.[0];
      // if (contactRemoteId) {
      //   potentialContact = incomingCallData?.contacts?.find((co: any) => co?.remoteId === contactRemoteId);
      // }

      // if (!potentialContact) {
      //   potentialContact = incomingCallData?.contacts?.[0];
      // }
    }

    if (!potentialContact) {
      potentialContact = {contactType: "Unknown", remoteId: null, phone: callDetails?.contactNumber, firstName: "Unknown", lastName: ""};
    }

    return potentialContact;
  };

  const potentialContact: any = getProtentialContact();

  const getCallerName = () => {
    let result = '';

    // If the call is from an agent, we have to handle it differently
    if (incomingCallType === CallConstants.IncomingCallTypeEnums.Agent) {
      result = callDetails?.agentName || 'Agent';
    } else if (incomingCallType === CallConstants.IncomingCallTypeEnums.Customer) {
      if (potentialContact?.remoteId) {
        result = `${potentialContact?.firstName || ''} ${potentialContact?.lastName || ''}`;
      } else {
        result = 'Unknown';
      }
    }

    dispatch(setMissedCallCallee(result));
    return result;
  };

  const getCallerAccount = () => {
    return `${potentialContact?.account?.name || ''}`;
  };

  const callerName = getCallerName();
  const callerAccount = getCallerAccount();

  const handleCallerAvatarClick = () => {
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
    let contactRemoteId = potentialContact?.remoteId;
    // Navigate to SF object details
    if (contactRemoteId) {
      SaleforceService.navigateToObject(contactRemoteId);
    } else {
      SaleforceService.callingObjectNavigation(callDetails?.contactNumber, callDetails?.contactId, false);
    }
  };

  const handleCallAnswerPress = () => {
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);

    const contactRemoteId = callDetails?.contactRemoteId;
    const sfContact = {...potentialContact, contactId: callDetails?.contactId}
    console.log("handleCallAnswerPress params: ", {contactRemoteId, sfContact})
    
    if (contactRemoteId !== sfContact?.remoteId) {
      let contactDetails = sfContact;
      
      if (!contactDetails || (typeof contactDetails === 'object' && Object.keys(contactDetails).length === 0)) {
        contactDetails = {contactType: 'Unknown', phone: callDetails?.contactNumber, contactId: callDetails?.contactId};
      }
      const callRequest = {CallId: callDetails?.callRecordId, ContactDetails: contactDetails};
      CallCentreService.assignObjectToCall(authData?.authUser.tenantCode, authData?.authUser.AccessToken, callRequest);
    }
    // Navigate to SF object details
    if (sfContact?.remoteId) {
      SaleforceService.navigateToObject(sfContact?.remoteId);
    } else {
      SaleforceService.callingObjectNavigation(callDetails?.contactNumber, callDetails?.contactId, false);
    }
    
    dispatch(addCallingContact(sfContact));

    onCallAnswerPress();
  };

  const didPressedMoreContacts = () => {
    if (incomingCallData?.callDetails?.contactNumber) {
      SaleforceService.callingObjectNavigation(incomingCallData?.callDetails?.contactNumber, '', true)
    }
  };

  const IncomingCallForwardInformation = (
    <IncomingCallPanelThemer.CallForwardInfo
     >
      <Typography.H4 variant={TypographyVariant.light}
        style={{
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '21px',}}
      >{`Forwarded by ${callDetails?.callForwardedByUserName}`}</Typography.H4>
      <Avatar
        id={`${id}-Call-Forward-Avatar`}
        style={{ width: 24, height: 24, marginLeft: 8 }}
        fallbackTextStyle={{ fontSize: 10, fontWeight: 600 }}
        url={callDetails?.callForwardedByUserName}
        fallbackLabel={callDetails?.callForwardedByUserName as string}
        alt="Forwarded Caller Avatar"
      />
    </IncomingCallPanelThemer.CallForwardInfo>
  );

  return (
    <IncomingCallPanelThemer.PanelContainer
     >
      <IncomingCallPanelThemer.CallPanel
        >
        <IncomingCallPanelThemer.CallContent
         >
          <IncomingCallPanelThemer.ContactDetailsContent
           >
            <div onClick={handleCallerAvatarClick}>
              <Avatar
                id={`${id}-Caller-Avatar`}
                className={{height: '150px !important',
                  width: '150px !important',}}
                alt="Incoming-Call-Panel-Caller Avatar"
                url={null}
                fallbackLabel={callerName}
                fallbackTextStyle={{fontSize: '60px !important', color: '#ffffff'}}
              /> 
            </div>
            <IncomingCallPanelThemer.ContactDetails
             >
              <IncomingCallPanelThemer.ContactName
               >
                <Typography.H2 variant={TypographyVariant.light}
                 style={{
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: '27px',}}
                  >{callerName}</Typography.H2>
                {contactSearchByPhoneDataCount > 1 && (
                  <Button
                    id={`${id}-Contact-Count`}
                    style={{ color: '#FFFFFF' }}
                    variant={ButtonVariant.text}
                    onClick={didPressedMoreContacts}
                  >
                    {`(${contactSearchByPhoneDataCount - 1} other contacts)`}
                  </Button>
                )}
              </IncomingCallPanelThemer.ContactName>
              <Typography.H2 variant={TypographyVariant.light}
                  style={{
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: '27px',}}
                >
                {incomingCallData?.callDetails?.contactNumber}</Typography.H2>
              {callerAccount &&
                <IncomingCallPanelThemer.Account
                 >
                  <FontAwesomeIcon icon={faBuilding as IconProp}/>
                  <Typography.H4 variant={TypographyVariant.light}
                  style={{fontWeight: 600,
                    fontSize: 14,
                    lineHeight: '21px',}}
                  >{callerAccount}</Typography.H4>
                </IncomingCallPanelThemer.Account>
              }  
              
              {locationNode}
              {incomingCallData?.callDetails?.callForwardedByUserName && IncomingCallForwardInformation}
            </IncomingCallPanelThemer.ContactDetails>
          </IncomingCallPanelThemer.ContactDetailsContent>
          <IncomingCallPanelThemer.CallActions
           >     
            <IconButton id={`${id}-Answer-Button`} size={IconButtonSize.extraLarge} theme={IconButtonTheme.light}
              style={{ mr: '60px', backgroundColor: 'green'}}
              onClick={handleCallAnswerPress}>  
              <FontAwesomeIcon icon={faPhone as IconProp} />
            </IconButton>
            <IconButton id={`${id}-Hangup-Button`} size={IconButtonSize.extraLarge} theme={IconButtonTheme.light}
              style={{ backgroundColor: 'red'}}
              onClick={onCallHangupPress}>       
              <FontAwesomeIcon icon={faPhoneHangup as IconProp} />
            </IconButton>      
          </IncomingCallPanelThemer.CallActions>
        </IncomingCallPanelThemer.CallContent>    
    </IncomingCallPanelThemer.CallPanel>
    </IncomingCallPanelThemer.PanelContainer>      
  );
});

IncomingCallPanelComponent.defaultProps = {};

export default IncomingCallPanelComponent;